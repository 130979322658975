.profile-section{
    height: 485px;
    background-color: #4c5364;
}

.profile-header-text{
    text-align: center;
    padding-top: 60px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0.3em;
    font-weight: 700;
    color: #fafafa;
    font-size: 12px;
    text-transform: uppercase;
}
.profile-footer-text{
    /* text-align: center; */
    padding-bottom: 20px;
    font-family: 'Open Sans', sans-serif;
    /* letter-spacing: 0.3em; */
    /* font-weight: 700; */
    color: #fafafa;
    /* font-size: 12px; */
    text-transform: uppercase;
}
.accessories-container{
    padding-top: 30px;
    width: 100%;
    justify-content: center;
}
.image-container{
    height: 285px;
}
.t-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}