.laptop {
    width: 500px;
}

.laptop .screen {
    position: relative;
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
    background: #1a1a1a;
    border: 1px solid rgba(0, 0, 0, .01);
    border-radius: 12px 12px 0 0;
    box-shadow: 
        0 0 0 3px rgba(64, 64, 64, 1),
        inset 0 0 100px 0 rgba(0, 0, 0, .75);
}

.laptop .screen::before {
    content: "";
    display: block;
    width: 480px;
    height: 270px;
}

.laptop .screen::after {
    content: "MacBook Pro";
    position: absolute;
    bottom: .5px;
    left: 50%;
    transform: translateX(-50%);
    font: 500 6px 'Montserrat';
    letter-spacing: .2px;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, .6)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.laptop .screen .lcd {
    position: absolute;
    top: 3.8%;
    bottom: 3.5%;
    left: 2.2%;
    right: 2.2%;
    overflow: hidden;
    border: 1px solid;
    border-color: #000 #202020 #282828 #000;
    border-radius: 3px;
}

.laptop .screen .lcd img {
    width: 100%;
    height: 100%;
}

.laptop .screen .glass-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        135deg,
        rgba(255, 255, 255, .175) 0%,
        rgba(255, 255, 255, 0) 50%
    );
}

.laptop .screen .screen-reflection {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.laptop .keyboard {
    position: relative;
    width: 100%;
}

.laptop .keyboard.top {
    height: 3px;
    border-radius: 1px 1px 0 0;
    background:
        linear-gradient(
            90deg,
            rgba(255, 255, 255, .5) 0%,
            rgba(0, 0, 0, .75) 2%,
            rgba(0, 0, 0, .33) 7%,
            rgba(0, 0, 0, 0) 100%
        ),
        linear-gradient(
            90deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, .33) 93%,
            rgba(0, 0, 0, .75) 98%,
            rgba(255, 255, 255, .5) 100%
        ),
        linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 20%,
            rgba(255, 255, 255, .35) 33%,
            rgba(255, 255, 255, .35) 35%,
            rgba(0, 0, 0, 0) 100%
        ),
        linear-gradient(
            0deg,
            rgba(20, 20, 20, 1) 0%,
            rgba(20, 20, 20, 1) 100%
        );
}

.laptop .keyboard.top::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 12%;
    height: 30%;
    background: rgba(0, 0, 0, .5);
}

.laptop .keyboard.bottom {
    height: 9px;
    border-radius: 0 0 33% 33%;
    background:
        linear-gradient(
            90deg,
            rgba(255, 255, 255, .35) 0%,
            rgba(0, 0, 0, .75) 2%,
            rgba(0, 0, 0, .5) 7%,
            rgba(0, 0, 0, 0) 50%
        ),
        linear-gradient(
            90deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, .5) 83%,
            rgba(0, 0, 0, .75) 98%,
            rgba(255, 255, 255, .35) 100%
        ),
        linear-gradient(
            0deg,
            rgba(0, 0, 0, .75) 0%,
            rgba(0, 0, 0, .25) 33%,
            rgba(255, 255, 255, 1) 34%,
            rgba(255, 255, 255, 1) 100%
        ),
        linear-gradient(
            0deg,
            rgba(200, 200, 200, 1) 0%,
            rgba(200, 200, 200, 1) 100%
        );
}

.laptop .keyboard.bottom::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 12%;
    height: 40%;
    border-radius: 0 0 .5vw .5vw;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, .75) 0%,
        rgba(0, 0, 0, .5) 100%
    );
}
.startup-image{
    animation: fadein 2s;
}